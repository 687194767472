<template>
	<!-- <div style=""> -->
	<!-- <svg ref="barcode"></svg> -->
	<img :code="code" ref="barcode" class="barcode" />
	<!-- </div> -->
</template>

<script>
	import JsBarcode from 'jsbarcode';

	export default {
		name: 'barcode',

		// props: ['code'],
		props: {
			// mobile:"",
			code: {
				default: function() {
					return '';
				},
				type: String
			},
			options: {
				default: function() {
					return {
						// format: "auto", "auto" CODE128
						// lineColor: "#0aa",
						// width:4,
						// height:50,
						// displayValue: false
					};
				},
				type: Object
			},
		},
		data() {
			return {
				// dialogFormVisible: false,
			};
		},
		watch: {
			code: function(newVal, oldVal) {
				// console.log('watchKey code', newVal);
				this.initData();
			}
		},
		created() {
			// console.log('created');
			this.initData();
		},
		mounted() {
			// console.log('monted');
		},
		methods: {
			initData() {
				if (this.code) {
					this.getBarCode(this.code);
				}
			},
			getBarCode(value) {
				this.$nextTick(() => {
					try {
						let barcode_ref = this.$refs.barcode;
						let options = Object.assign({
							font:'Arial'
						},this.options);
						JsBarcode(barcode_ref, value, options);
					} catch (e) {
						console.log(e);
						this.$message.warning(this.$t('i18nn_6b3c6618251b6db5'));
					}
				});
			}
		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	/*.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }*/
</style>