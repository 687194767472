<template>
	<div>
		<!-- 导入excel -->
		<!-- <el-dialog :title="$t('i18nn_17a941b7c4c108fe')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile" width="1200px" top="0"> -->
		<!-- <div class="top_action no_print"><el-button type="primary" icon="el-icon-printer" @click="printerAction()">{{$t('i18nn_886072e393710563')}}</el-button></div> -->
		<!-- <div class="top_action no_print">
			
		</div> -->
		<div class="printMain body-bg">
			<div class="printer-logo"><img :src="$store.state.sysShow.logoInfo.logo" width="80" height="auto" /></div>
			<div style="" v-loading="loading_load">

				<div class="printer_con">
					<h1 class="printer_tit" style="">
						<div>未扫描SKU明细</div>
					</h1>
					<div class="">
						<div>
							<barcode :code="filterData.batchNo" :options="{height:50}"></barcode>
							<!-- {{filterData.batchNo}} -->
						</div>
					</div>

					<div class="printer_block">

						<div class="count-prt-con">
							<table class="simTable printerTable" style="">

								<tr>
									<th width="100">
										SKU
									</th>
									<th width="100">
										数量
									</th>
									<th width="100">
										库位
									</th>

								</tr>
								<tbody class="text_small">
									<tr v-for="(itemP,indexP) in allData.goodsSkuInfo" :key="indexP">
										<td>
											<div><span>{{itemP.goodsSku}}</span></div>
										</td>
										<td>
											<div><span>{{itemP.quantity}}</span></div>
										</td>
										<td>
											<div v-for="(item2,index2) in itemP.place" :key="index2">
												<span>{{item2}}</span>
											</div>
										</td>

									</tr>
								</tbody>
							</table>
						</div>

					</div>

				</div>

			</div>
		</div>


	</div>
</template>
<script>
	import barcode from '@/components/Common/barcode.vue';
	export default {
		metaInfo() {
			return {
				title: this.filterData && this.filterData.batchNo ? this.filterData.batchNo + '-未扫描SKU明细' : 'HYTX-未扫描SKU明细',
			}
		},
		components: {
			barcode,
		},
		data() {
			return {
				filterData: {
					batchNo: '',
					outLpn: ''
				},
				// carrier: [],
				allData: [],
				// hasDet: '0',
				// showDet: false,
				// showOrder: true,
				// showRecevier: true,
				// showTracking: true,
				// showPackNo: true,
				// listUrl: [],
				loading_load: false,
				// priterData: [],
				// ValueAddOpenTime:''
			};
		},
		// watch: {
		//   openTime: function(newVal, oldVal) {
		//     console.log('openTime');
		//     this.dialogFile = true;
		//     this.initData();
		//   }
		// },
		//创建时
		created() {
			// this.getPageData();
			if (this.$route.query && this.$route.query.batchNo) {
				this.filterData.batchNo = this.$route.query.batchNo;
			}
			if (this.$route.query && this.$route.query.outLpn) {
				this.filterData.outLpn = this.$route.query.outLpn;
			}

			this.initData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				this.getPageData();
			},

			//请求分页数据
			getPageData() {
				this.loading_load = true;

				this.$http
					.put(this.$urlConfig.WhDsScanPrintDet, {
						batchNo: this.filterData.batchNo ? this.filterData.batchNo : null,
						outLpn: this.filterData.outLpn ? this.filterData.outLpn : null,
					})
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);

						this.loading_load = false;
						if (200 == data.code && data.data && data.data.noPrintGoodsSkuInfo) {
							
							this.allData = data.data.noPrintGoodsSkuInfo;

						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// @import '@/assets/css/printer.less'
	@import url(../assets/css/printer.less);
</style>